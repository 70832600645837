import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PinPadButtonComponent } from './pin-pad-button.component';
import { PinPadInputComponent } from './pin-pad-input.component';
import { PIN_LENGTH } from './pin.helper';

@Component({
  selector: 'app-pin-pad',
  standalone: true,
  imports: [PinPadButtonComponent, PinPadInputComponent],
  template: `
    <app-pin-pad-input [inputCount]="pin?.length" class="inputs" [pinMaxLength]="pinMaxLength" />
    <div class="buttons">
      @for (button of buttons; track button) {
        <app-pin-pad-button
          [number]="button?.number"
          [alpha]="button?.alpha"
          class="button"
          [disabled]="pin?.length === pinMaxLength"
          (onButtonClick)="updatePin(button?.number)"
        />
      }
      @if (optionalButton) {
        <app-pin-pad-button [label]="optionalButton" class="button" (onButtonClick)="optionalButtonClicked()" />
      }
      <app-pin-pad-button
        number="0"
        class="button"
        (onButtonClick)="updatePin('0')"
        [disabled]="pin?.length === pinMaxLength"
      />
      @if (!biometricEnabled || pin?.length > 0) {
        <app-pin-pad-button icon="fa-delete-left fa-lg" class="button" (onButtonClick)="deletePin()" />
      }
      @if (biometricEnabled && pin?.length === 0) {
        <app-pin-pad-button icon="fa-face-viewfinder fa-2xl" class="button" (onButtonClick)="triggerBiometric()" />
      }
    </div>
  `,
  styleUrls: ['./pin-pad.component.scss'],
})
export class PinPadComponent {
  @Input() pin: string = '';
  @Input() optionalButton?: string;
  @Input() biometricEnabled?: boolean;
  pinMaxLength = PIN_LENGTH;
  @Output() pinUpdate = new EventEmitter<PinPadComponentOutput>();

  buttons = [
    { number: '1', alpha: ' ' },
    { number: '2', alpha: 'ABC' },
    { number: '3', alpha: 'DEF' },
    { number: '4', alpha: 'GHI' },
    { number: '5', alpha: 'JKL' },
    { number: '6', alpha: 'MNO' },
    { number: '7', alpha: 'PQRS' },
    { number: '8', alpha: 'TUV' },
    { number: '9', alpha: 'WXYZ' },
  ];

  updatePin(number: string): void {
    if (this.pin?.length < this.pinMaxLength) {
      // only update the pin if it under the pin length
      const pin = this.pin + number;
      this.pinUpdate.emit({
        pin,
        type: pin?.length === this.pinMaxLength ? PinPadEventEnum.pinCompleted : PinPadEventEnum.pinUpdated,
      });
    }
  }

  deletePin(): void {
    if (this.pin?.length) {
      const pin = this.pin.slice(0, -1);
      this.pinUpdate.emit({
        pin,
        type: PinPadEventEnum.pinUpdated,
      });
    }
  }

  triggerBiometric(): void {
    this.pinUpdate.emit({
      type: PinPadEventEnum.triggerBiometric,
    });
  }

  optionalButtonClicked(): void {
    this.pinUpdate.emit({
      type: PinPadEventEnum.optionalButtonClicked,
    });
  }
}

export enum PinPadEventEnum {
  optionalButtonClicked = 'optionalButtonClicked',
  pinUpdated = 'pinUpdated',
  pinCompleted = 'pinCompleted',
  triggerBiometric = 'triggerBimetric',
}

export interface OptionalButtonClicked {
  type: PinPadEventEnum.optionalButtonClicked;
}

export interface BiometricButtonClicked {
  type: PinPadEventEnum.triggerBiometric;
}
export interface PinPadComponentUpdated {
  type: PinPadEventEnum.pinUpdated;
  pin: string;
}
export interface PinPadComponentCompleted {
  type: PinPadEventEnum.pinCompleted;
  pin: string;
}

export type PinPadComponentOutput =
  | OptionalButtonClicked
  | PinPadComponentUpdated
  | PinPadComponentCompleted
  | BiometricButtonClicked;

import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

import { environment } from '@environments/environment';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, first, of, retry } from 'rxjs';

import { CustomerFormsStore } from './customer-forms.store';

dayjs.extend(utc);

export interface FetchParticipantsResponse {
  ContactEvidenceRequired: boolean;
  GroupEvidenceRequired: boolean;
  EmailAddress: string;
}

@Injectable()
export class CustomerFormsService {
  private http = inject(HttpClient);
  private store = inject(CustomerFormsStore);
  private router = inject(Router);

  private formTokenExpiryTimer: ReturnType<typeof setTimeout>;

  constructor() {
    // This will run every time formTokenParams is updated to refresh the session expiry
    effect(() => {
      // Clear existing timer
      if (this.formTokenExpiryTimer) this.clearTokenExpiryTimer();

      // Update expiry timer
      if (this.store.formTokenParams()) {
        this.formTokenExpiryTimer = this.createTokenExpiryTimer(this.store.formTokenParams().e);
      }
    });
  }

  // GET request to fetch list of borrower emails for the loan application
  public fetchParticipants() {
    const participantsURL = `${environment.endpointApiPortalTwo}/v1/evidence/${this.store.loanApplicationId()}/participants`;

    return this.http.get<FetchParticipantsResponse[]>(participantsURL, {
      headers: {
        Authorization: this.store.loginAuthHeader(),
      },
    });
  }

  // POST request to send a login link to the selected email address
  public selectParticipant(email: string) {
    const selectURL = `${environment.endpointApiPortalTwo}/v1/evidence/${this.store.loanApplicationId()}/participants/select?email=${email}`;

    return this.http
      .post(selectURL, null, {
        headers: {
          Authorization: this.store.loginAuthHeader(),
        },
      })
      .pipe(
        retry(1),
        catchError((error: HttpErrorResponse) => {
          // Server responds with 409 when second email is sent in a short timeframe
          // So consider this a success and forms-login component will treat as a success.
          if (error.status === 409) return of(true);

          // Throw error in all other cases. forms-login component shows error.
          throw error;
        }),
        first(),
      );
  }

  public clearTokenExpiryTimer(): void {
    if (!this.formTokenExpiryTimer) return;

    clearTimeout(this.formTokenExpiryTimer);
    this.formTokenExpiryTimer = null;
  }

  // Returns reference to a timeout that will end the session 1 minute before the expiry time.
  private createTokenExpiryTimer(expiresUtcString: string): ReturnType<typeof setTimeout> {
    const now = dayjs();
    const expiresAt = dayjs.utc(expiresUtcString);
    const millisecondsToExpiry = expiresAt.diff(now);

    // End session 1 minute before token expires
    const timerExpiry = millisecondsToExpiry - 60000;

    return setTimeout(() => {
      this.store.clearTokens();
      this.router.navigate(['customer-forms', 'ended']);
    }, timerExpiry);
  }
}

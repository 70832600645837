import { HttpErrorResponse } from '@angular/common/http';

import { HttpErrorState } from '../networkLayer/interceptor.types';

export enum LoggerRequestType {
  apiError = 'apiError',
  jsError = 'jsError',
}

export type LoggerRequest = LoggerRequestApiError | LoggerRequestJsError;

export interface LoggerRequestApiError {
  type: LoggerRequestType.apiError;
  message: string;
  userId?: number;
  errorResponse?: HttpErrorResponse;
  errorState?: HttpErrorState[];
}

export interface LoggerRequestJsError {
  type: LoggerRequestType.jsError;
  error: Error;
  userId?: number;
}

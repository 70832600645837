import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum EnumChipType {
  PendingUser = 'pending-user',
  Pending = 'pending',
  Active = 'active',
  Warning = 'warning',
  Draft = 'draft',
}

@Component({
  selector: 'app-chip',
  template: `<div class="chip {{ type }}" [class.placeholder]="label === undefined">{{ label }}</div>`,
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ChipComponent {
  @Input() type: EnumChipType;
  @Input() label: string;
}

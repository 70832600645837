import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SecurityState } from './security.types';

export const selectSecurityState = createFeatureSelector<SecurityState>('security');

export const selectSecurityUserMfa = createSelector(
  selectSecurityState,
  securityState => securityState?.userMfaResponse,
);
export const selectSecurityUserMfaIsLoading = createSelector(
  selectSecurityState,
  securityState => securityState?.userMfaIsLoading,
);
export const selectSecurityUserMfaIsLoaded = createSelector(
  selectSecurityState,
  securityState => securityState?.userMfaIsLoaded,
);
export const selectSecurityUserMfaIsError = createSelector(
  selectSecurityState,
  securityState => securityState?.userMfaIsError,
);

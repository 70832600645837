import { NgStyle } from '@angular/common';
import { Component } from '@angular/core';

import { NativeAuthService } from '@views/misc/auth/native-auth.service';

@Component({
  selector: 'app-native-app-status-bar-spacer',
  standalone: true,
  imports: [NgStyle],
  template: `
    <div class="native-app-status-bar-spacer" [ngStyle]="{ height: nativeAuthService?.safeAreaTop + 'px' }"></div>
  `,
  styleUrls: ['./native-app-status-bar-spacer.component.scss'],
})
export class NativeAppStatusBarSpacerComponent {
  constructor(public nativeAuthService: NativeAuthService) {}
}

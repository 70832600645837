import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HttpErrorState, HttpErrorsGrouped, IFormErrorsState } from './interceptor.types';

export const selectFormErrorsState = createFeatureSelector<IFormErrorsState>('apiError');
export const selectFormErrorsMfaInProgress = createSelector(selectFormErrorsState, state => state?.mfaInProgress);
export const selectFormErrors = createSelector(selectFormErrorsState, (state): IFormErrorsState => state);
export const selectFormErrorsInvalidId = createSelector(
  selectFormErrors,
  formErrors => formErrors && formErrors.errors && formErrors.errors.find(error => error.errorCode === '1001'),
);
export const selectFormErrorsAll = createSelector(selectFormErrorsState, (state): HttpErrorState[] =>
  state.errors.filter(error => error.propertyName),
);
export const selectFormErrorsCustom = (propertyNames: string[]) =>
  createSelector(selectFormErrorsAll, (formErrorsAll: HttpErrorState[]): HttpErrorState[] => {
    if (formErrorsAll && propertyNames) {
      const propertyNamesLowercase: string[] = propertyNames.map(name => name.toLowerCase());
      return formErrorsAll.filter(error => propertyNamesLowercase.includes(error.propertyName.toLowerCase()));
    }
    return [];
  });

export const selectFormErrorsGrouped = (propertyNames: string[]) =>
  createSelector(selectFormErrorsAll, (formErrorsAll: HttpErrorState[]): HttpErrorsGrouped => {
    const errors: HttpErrorsGrouped = {};
    if (formErrorsAll && propertyNames) {
      propertyNames.map(propertyName => {
        errors[propertyName] = formErrorsAll.filter(
          error => error?.propertyName.toLowerCase() === propertyName.toLowerCase(),
        );
      });
    }
    return errors;
  });

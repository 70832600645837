import shortid from 'shortid';

/**
 * When generating a unique id during SSR the import will return `undefined`. In
 * that regard, we use a cheap generator before the app can rehydrate client side.
 */
export const createUniqueId = (): string => {
  const hasImport = shortid && shortid.generate;
  return hasImport ? shortid.generate() : `${Math.random()}`;
};

import { DialogRef } from '@angular/cdk/dialog';
import { Component, Input } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

import { MainToolbarComponent } from '@components/main-toolbar/main-toolbar.component';

@Component({
  selector: 'app-full-screen-dialog-content',
  standalone: true,
  imports: [MainToolbarComponent, MatIcon, MatIconButton],
  template: `
    <app-main-toolbar>
      <button mat-icon-button aria-label="Back" (click)="dialogRef.close()" class="back-button">
        <mat-icon fontSet="fa-solid" fontIcon="fa-chevron-left" />
      </button>
      <div class="title" data-testId="dialog-title">{{ title }}</div>
    </app-main-toolbar>
    <div class="content">
      <ng-content />
    </div>
  `,
  styleUrls: ['./full-screen-dialog-content.component.scss'],
})
export class FullScreenDialogContentComponent {
  @Input() title: string;
  constructor(public dialogRef: DialogRef) {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { NativeAuthService } from '@views/misc/auth/native-auth.service';

import { PinPadComponent, PinPadComponentOutput, PinPadEventEnum } from '@components/pin/pin-pad.component';
import { StepHeaderComponent } from '@components/step-header/step-header.component';

import { newPinInstruction } from './pin.helper';

@Component({
  selector: 'app-set-pin',
  standalone: true,
  imports: [StepHeaderComponent, PinPadComponent],
  template: `
    <app-step-header [step]="step" [steps]="steps" [primaryHeading]="primaryHeading" [secondaryHeading]="instruction" />

    @if (pinSet) {
      <div class="icon-wrapper">
        <i class="fa-solid fa-circle-check icon success"></i>
      </div>
    } @else {
      <app-pin-pad (pinUpdate)="pinUpdate($event)" [pin]="pinPadValue" />
    }
  `,
  styleUrls: ['./set-pin.component.scss'],
})
export class SetPinComponent implements OnInit {
  @Input() step?: number;
  @Input() steps?: number;
  @Input() primaryHeading = 'Setup pin login';
  @Output() pinCompleted = new EventEmitter();
  attempts = 0;
  pinSet = false;
  newPin: string;
  pinPadValue = ''; // This is the dynamic pin value of pin pad
  instruction: string;

  constructor(private nativeAuthService: NativeAuthService) {}

  ngOnInit(): void {
    // Set the intial the instruction
    this.instruction = newPinInstruction({ pinEntered: this.newPin !== undefined, attempts: this.attempts });
  }

  pinUpdate(e: PinPadComponentOutput): void {
    if (e.type === PinPadEventEnum.pinCompleted) {
      this.pinPadValue = e.pin;
      if (this.newPin === undefined) {
        // If new pin is undefined then save it to check with the confirmation pin in the next step
        this.newPin = e.pin;
      } else if (this.newPin === e.pin) {
        // If the new pin is defined then check if the confirm value matches, if it does then save the pin and complete the process
        this.nativeAuthService.setPin(this.newPin, successful => {
          this.pinSet = successful;
          this.pinCompleted.emit();
        });
      } else {
        // if the new pin is defined but doesn't match the confirmation pin then clear the new pin and try again
        this.newPin = undefined;
        this.attempts = this.attempts + 1;
      }
      // Update the instruction
      this.instruction = newPinInstruction({ pinEntered: this.newPin !== undefined, attempts: this.attempts });

      // Clear the pin pad after each completed step
      setTimeout(() => {
        // Delay by half a second so that that the user can still see the last pin digit was entered otherwise it appears like its skipped
        this.pinPadValue = '';
      }, 500);
    } else if (e.type === PinPadEventEnum.pinUpdated) {
      this.pinPadValue = e.pin;
    }
  }
}

import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Component, ElementRef, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'app-help-tip',
  standalone: true,
  imports: [OverlayModule, MatRipple],
  template: `
    <div #buttonRef class="help-button" (click)="displayOverlay()" matRipple>
      <i class="fa-solid fa-circle-question icon"></i> <ng-content select="[label]" />
    </div>

    <!-- This template displays the overlay content and is connected to the button -->
    <ng-template #tipRef>
      <div class="tool-tip mat-elevation-z3"><ng-content select="[tip]" /></div>
    </ng-template>
  `,
  styleUrls: ['./help-tip.component.scss'],
})
export class HelpTipComponent {
  @ViewChild('tipRef') tipRef: TemplateRef<any>;
  @ViewChild('buttonRef') buttonRef: ElementRef;

  constructor(
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
  ) {}

  displayOverlay() {
    const overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      maxWidth: 300,
      maxHeight: 300,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.buttonRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
    });
    overlayRef.attach(new TemplatePortal(this.tipRef, this.viewContainerRef));
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }
}

import { createAction, props } from '@ngrx/store';

import { HttpErrorState } from './interceptor.types';

export const interceptorMfaStarted = createAction('INTERCEPTOR/MFA_STARTED');
export const interceptorMfaEnded = createAction('INTERCEPTOR/MFA_ENDED');
export const interceptorAddErrors = createAction(
  'INTERCEPTOR/ADD_ERRORS',
  props<{
    payload: {
      errors: HttpErrorState[];
    };
  }>(),
);
export const interceptorClearErrors = createAction('INTERCEPTOR/CLEAR_ERRORS');

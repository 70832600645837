import { Component } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';

import { NativeAppStatusBarSpacerComponent } from '@components/native-app-status-bar-spacer/native-app-status-bar-spacer.component';

@Component({
  selector: 'app-main-toolbar',
  standalone: true,
  imports: [NativeAppStatusBarSpacerComponent, MatToolbarModule],
  template: `
    <app-native-app-status-bar-spacer class="native-app-status-bar-spacer" />
    <mat-toolbar color="accent" class="main-toolbar">
      <header class="toolbar-content">
        <ng-content />
      </header>
    </mat-toolbar>
  `,
  styleUrls: ['./main-toolbar.component.scss'],
})
export class MainToolbarComponent {}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { SqButtonModule } from '@components/button/sq-button.module';

@Component({
  selector: 'app-new-user-step-actions',
  standalone: true,
  imports: [SqButtonModule],
  template: `
    <sq-button-wrapper>
      @if (primaryLabel) {
        <button
          sqButton
          variant="primary"
          type="button"
          (click)="buttonClicked.emit('primary')"
          data-testid="primary-button"
        >
          {{ primaryLabel }}
        </button>
      }
      @if (secondaryLabel) {
        <button
          sqButtonOutline
          variant="primary"
          type="button"
          data-testid="secondary-button"
          (click)="buttonClicked.emit('secondary')"
        >
          {{ secondaryLabel }}
        </button>
      }
    </sq-button-wrapper>
  `,
  styleUrls: ['./new-user-step-actions.component.scss'],
})
export class NewUserStepActionsComponent {
  @Input() primaryLabel?: string;
  @Input() secondaryLabel?: string;
  @Output() buttonClicked = new EventEmitter<'primary' | 'secondary'>();
}

export type NewUserStepActionEvent = 'primary' | 'secondary';

export const PIN_LENGTH = 6;
export const MAX_ATTEMPTS = 5;

export function newPinInstruction({ pinEntered, attempts }: { pinEntered: boolean; attempts: number }): string {
  if (pinEntered) {
    return 'Re-enter your PIN.';
  } else if (attempts > 0) {
    return "PIN didn't match, please try again.";
  } else {
    return `Enter a ${PIN_LENGTH} digit PIN.`;
  }
}

export function loginPinInstruction({ attempts }: { attempts: number }): string {
  if (attempts < 2) {
    return '';
  } else if (attempts === MAX_ATTEMPTS || attempts > MAX_ATTEMPTS) {
    return 'PIN has been disabled.';
  } else {
    const remainingAttempts = MAX_ATTEMPTS - attempts;
    return `You have ${remainingAttempts} more attempt${remainingAttempts === 1 ? '' : 's'}.`;
  }
}

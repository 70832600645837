import { Component } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-page-loader',
  standalone: true,
  imports: [MatProgressSpinner],
  template: ` <mat-spinner class="spinner" diameter="50" color="accent" aria-label="loading" /> `,
  styleUrls: ['./page-loader.component.scss'],
})
export class PageLoaderComponent {}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-step-header',
  standalone: true,

  template: `
    @if (steps > 1) {
      <p class="count">STEP {{ step }} of {{ steps }}</p>
    }
    @if (primaryHeading) {
      <h2 class="label">{{ primaryHeading }}</h2>
    }
    @if (secondaryHeading !== undefined) {
      <p class="description">&nbsp;{{ secondaryHeading }}&nbsp;</p>
    }
  `,
  styleUrls: ['./step-header.component.scss'],
})
export class StepHeaderComponent {
  @Input() primaryHeading?: string;
  @Input() secondaryHeading?: string;
  @Input() step?: number;
  @Input() steps?: number;
}

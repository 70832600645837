import qs from 'qs';

export const getQueryParams = (): string => {
  const { search } = window.location;

  // Remove the prepended "?".
  return search ? search.slice(1) : '';
};

export const parseQueryParams = (params = getQueryParams()): any => {
  return qs.parse(params, { ignoreQueryPrefix: true });
};

export const stringifyQueryParams = (params = {}): string => {
  return qs.stringify(params, { addQueryPrefix: true });
};

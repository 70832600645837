import { Intercom } from '@sencrop/capacitor-intercom';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-nz';
import { initializeApp } from 'firebase/app';
import rg4js from 'raygun4js';

import { environment } from '@environments/environment';

import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { Store } from '@ngrx/store';

import { Subject, filter, map, takeUntil } from 'rxjs';

import { AuthService } from '@views/misc/auth/auth.service';
import { constantsFetchInvestorStart } from '@views/misc/constants/constants.actions';
import { selectConstantPhoneNumber } from '@views/misc/constants/constants.selectors';
import { navigationResetBackRoute, navigationUpdateCurrentViewTitle } from '@views/misc/navigation/navigation.actions';

import { CapacitorPlatformTypes } from '@core/capacitor/capacitor.types';
import { AnalyticsService } from '@core/utilities/analytics.service';
import { BrazeService } from '@core/utilities/braze.service';

import packageInfo from '../../package.json';

@Component({
  selector: 'app-root',
  template: `@if (isNativePlatform) {
      <app-privacy-screen />
    }

    <div class="app">
      <router-outlet />
    </div> `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<boolean>();
  isNativePlatform = Capacitor.isNativePlatform();
  load = false;
  fireBaseActionListener: PluginListenerHandle;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta,
    private analyticsService: AnalyticsService,
    private store: Store,
    private dateAdapter: DateAdapter<Dayjs>,
    // Added to initiate the braze SDK
    private brazeService: BrazeService,
    public authService: AuthService,
    private zone: NgZone,
  ) {
    this.setPageTitle();
    this.initializeFirebase();
    this.initializeDeeplinkRedirects();
    this.initializeRaygun();

    Intercom.initialize({
      app_id: environment.intercomToken,
    });
  }

  ngOnInit(): void {
    this.analyticsService.init();

    // Init the delete session handler
    this.authService.handleDeleteUserSessionSuccess();

    // Get the investor constants in the app component so they are available in all sub components
    this.store.dispatch(constantsFetchInvestorStart());

    this.store
      .select(selectConstantPhoneNumber)
      .pipe(takeUntil(this.destroy$))
      .subscribe(phoneNumber => {
        this.metaService.updateTag({
          content: `Whether you're seeking a mortgage broker to arrange a home loan, after a personal loan, organising insurance or wanting to invest your money - call Squirrel today on ${phoneNumber} or apply online.`,
          name: 'description',
        });
      });

    this.setLocale('en-nz');
    this.brazeService.init();

    this.setupNativeApps();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.fireBaseActionListener !== undefined) {
      // remove notification event listener
      this.fireBaseActionListener.remove();
    }
  }

  initializeDeeplinkRedirects(): void {
    if (Capacitor.isNativePlatform()) {
      App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          // Example url: https://account.squirrel.co.nz/tabs/tab2
          // slug = /tabs/tab2
          const slug = event.url.split('.co.nz').pop();
          if (slug) {
            this.router.navigateByUrl(slug);
          }
          // If no match, do nothing - let regular routing
          // logic take over
        });
      });
    }
  }

  initializeRaygun(): void {
    if (environment.rayGunApiKey) {
      // Setup Raygun
      rg4js('apiKey', environment.rayGunApiKey);
      rg4js('enableCrashReporting', true);
      rg4js('filterSensitiveData', [
        'password',
        'newPassword',
        'currentPassword',
        'confirmPassword',
        'shortTermToken',
        'shortTermTokenExpiresInSeconds',
      ]);
      rg4js('setVersion', packageInfo?.version);
      rg4js('withCustomData', { platform: Capacitor.getPlatform() });
    }
  }

  initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    if (environment.firebase !== undefined) {
      initializeApp(environment.firebase);
    }
  }

  async setupNativeApps(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      // Native app listners
      this.fireBaseActionListener = await FirebaseMessaging.addListener('notificationActionPerformed', event => {
        // Save notification until its safe to use e.g. a confirmed authenticated state
        this.authService.setPendingNotification(event);
      });

      // Hide the splash on app launch
      await SplashScreen.hide({ fadeOutDuration: 0 });

      if (Capacitor.getPlatform() === CapacitorPlatformTypes.Android) {
        // Must use timeout here due to conflict with @capacitor/splash-screen version 5.0.6 - https://github.com/ionic-team/capacitor-plugins/issues/1160
        setTimeout(() => {
          // Display content under transparent status bar (Android only)
          StatusBar.setOverlaysWebView({ overlay: true });
          StatusBar.setStyle({ style: Style.Light });
        }, 500);
      } else {
        StatusBar.setStyle({ style: Style.Light });
      }
      this.authService.checkNativeVersionIsValid();
    }
  }

  setLocale(locale: string): void {
    dayjs.locale(locale);
    this.dateAdapter.setLocale(locale);
  }

  setPageTitle(): void {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          // Get the title from the lowest view
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return undefined;
        }),
      )
      .subscribe((title: string) => {
        const baseTitle = 'Squirrel';
        this.titleService.setTitle(title ? `${title} - ${baseTitle}` : title);
        // Set the page view title for global use
        this.store.dispatch(navigationUpdateCurrentViewTitle({ currentViewTitle: title }));
        // Reset the back route anytime the navigation changes so that the back button is hidden if not needed
        this.store.dispatch(navigationResetBackRoute());
      });
  }
}

import { createAction, props } from '@ngrx/store';

import { LegalCopyFetchResponse } from './legal-copy.types';

export const legalCopyFetchSecondaryMarketStart = createAction('LEGAL_COPY/FETCH_SECONDARY_MARKET_START');

export const legalCopyFetchSecondaryMarketSuccess = createAction(
  'LEGAL_COPY/FETCH_SECONDARY_MARKET_SUCCESS',
  props<{ payload: LegalCopyFetchResponse }>(),
);
export const legalCopyFetchSecondaryMarketFail = createAction('LEGAL_COPY/FETCH_SECONDARY_MARKET_FAIL');

export const legalCopyFetchDisclosureStatementStart = createAction('LEGAL_COPY/FETCH_DISCLOSURE_STATEMENT_START');

export const legalCopyFetchDisclosureStatementSuccess = createAction(
  'LEGAL_COPY/FETCH_DISCLOSURE_STATEMENT_SUCCESS',
  props<{ payload: LegalCopyFetchResponse }>(),
);
export const legalCopyFetchDisclosureStatementFail = createAction('LEGAL_COPY/FETCH_DISCLOSURE_STATEMENT_FAIL');

export const legalCopyFetchInvestorAgreementStart = createAction('LEGAL_COPY/FETCH_INVESTOR_AGREEMENT_START');

export const legalCopyFetchInvestorAgreementSuccess = createAction(
  'LEGAL_COPY/FETCH_INVESTOR_AGREEMENT_SUCCESS',
  props<{ payload: LegalCopyFetchResponse }>(),
);

export const legalCopyFetchInvestorAgreementFail = createAction('LEGAL_COPY/FETCH_INVESTOR_AGREEMENT_FAIL');

import { createAction, props } from '@ngrx/store';

import { UserMfaPostRequest, UserMfaResponse } from './security.types';

export const securityFetchUserMfaStart = createAction('SECUIRTY/FETCH_USER_MFA_START');
export const securityFetchUserMfaSuccess = createAction(
  'SECUIRTY/FETCH_USER_MFA_SUCCESS',
  props<{ payload: UserMfaResponse }>(),
);
export const securityFetchUserMfaFail = createAction('SECUIRTY/FETCH_USER_MFA_FAIL');

export const securityUpdateUserMfaStart = createAction(
  'SECUIRTY/UPDATE_USER_MFA_START',
  props<{ payload: UserMfaPostRequest }>(),
);
export const securityUpdateUserMfaSuccess = createAction(
  'SECUIRTY/UPDATE_USER_MFA_SUCCESS',
  props<{ payload: UserMfaResponse }>(),
);
export const securityUpdateUserMfaFail = createAction('SECUIRTY/UPDATE_USER_MFA_FAIL');

export const securityMfaManageStart = createAction('SECUIRTY/MFA_MANAGE_START');
export const securityMfaManageSuccess = createAction(
  'SECUIRTY/MFA_MANAGE_SUCCESS',
  props<{ payload: UserMfaResponse }>(),
);
export const securityMfaManageFail = createAction('SECUIRTY/MFA_MANAGE_FAIL');

export const securityReset = createAction('SECUIRTY/RESET');

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

import { StepHeaderComponent } from '@components/step-header/step-header.component';

import { PreferenceStorage } from '../auth/auth.types';
import { NativeAuthService } from '../auth/native-auth.service';
import { NewUserStepActionEvent, NewUserStepActionsComponent } from './new-user-step-actions.component';

@Component({
  selector: 'app-new-user-step-biometric',
  standalone: true,
  imports: [NewUserStepActionsComponent, StepHeaderComponent],
  template: `
    <app-step-header
      [step]="step"
      [steps]="steps"
      primaryHeading="Setup Biometric ID"
      secondaryHeading="Allows you to use your fingerprint or face to log in to the app."
    />

    @switch (biometricLoginSet) {
      @default {
        <i class="fa-light fa-face-viewfinder icon"></i>
      }
      @case (true) {
        <i class="fa-solid fa-circle-check icon success"></i>
      }
    }

    @switch (biometricLoginSet) {
      @default {
        <app-new-user-step-actions
          primaryLabel="Turn on"
          secondaryLabel="I'll do this later"
          (buttonClicked)="buttonClicked($event)"
        />
      }
      @case (true) {
        <app-new-user-step-actions
          [primaryLabel]="step === undefined || step === steps ? 'All done!' : 'Next'"
          (buttonClicked)="buttonClicked($event)"
        />
      }
    }
  `,
  styleUrls: ['./new-user-step-biometric.component.scss'],
})
export class NewUserStepBiometricComponent {
  @Input() step?: number;
  @Input() steps?: number;
  @Output() next = new EventEmitter();
  biometricLoginSet = false;

  constructor(private nativeAuthService: NativeAuthService) {}

  buttonClicked(e: NewUserStepActionEvent): void {
    if (e === 'primary' && this.biometricLoginSet === false) {
      this.nativeAuthService.enableBiometricLogin(successful => {
        this.biometricLoginSet = successful;
      });
    } else {
      this.next.emit();
    }
    // We save the preference so we don't ask the question again on this device
    Preferences.set({ key: PreferenceStorage.IntroBiometric, value: 'true' });
  }
}

export enum LoginStageEnum {
  Intro = 'Intro',
  BorrowerQuestion = 'BorrowerQuestion',
  LoginForm = 'LoginForm',
  PinPad = 'PinPad',
}

export interface LoginState {
  loginStage: LoginStageEnum;
}

import { truncateNumberWithPrecision } from '../number.utility';

const currencyFormatter = (() => {
  let formatter: Intl.NumberFormat;

  try {
    formatter = new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'NZD',
      currencyDisplay: 'symbol',
      useGrouping: true,
    });
  } catch (error) {
    formatter = null;
  }

  return formatter;
})();

export const formatCurrencyUtility = (value?: number, truncateDecimal?: boolean): string => {
  if (value === undefined) {
    // Skip formating if undefined
    return undefined;
  }
  if (value !== null) {
    // truncate value to remove extra decimal points without rounding
    value = truncateNumberWithPrecision(value, 2);
  }
  let formatedCurrency: string = currencyFormatter
    ? currencyFormatter.format(value).replace(/NZ/, '') // Do NOT show "NZ" next to EVERY "$" reference.
    : `$${value || '0.00'}`;

  if (truncateDecimal && formatedCurrency !== undefined) {
    // remove everything after decimal place
    formatedCurrency = formatedCurrency.split('.')[0];
  }
  return formatedCurrency;
};

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

/** Protect routes with "gated content" against un-authenticated users. */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  /**
   * Angular hook to prevent individual routes.
   * @see https://angular.io/guide/router#canactivate-requiring-authentication
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const url: string = state.url;

    return this.protectAgainstGatedContent(url);
  }

  /**
   * Angular hook to prevent "batched" child routes.
   * @see https://angular.io/guide/router#canactivatechild-guarding-child-routes
   */
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  /** Makes the assertion to check IF a single route is authenticated. */
  protectAgainstGatedContent = (url: string): boolean => {
    /** Leverage out AuthService to check he users current "logged in" status. */
    if (this.authService.checkIsLoggedIn()) {
      return true; // Tell Angular this route can be rendered ✅
    } else {
      /**
       * If the user is not authenticated to access the route then we send the
       * requested URL to the AuthService (to re-direct the user later).
       */
      this.authService.setRedirectUrl(url);

      /** We also send the user to the Login page so that they can authenticate */
      void this.router.navigate(['/login']);

      return false; // Tell Angular this route is out of bounds ❌
    }
  };
}

/** Make sure logged out views aren't viewed when logged in */
@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  /**
   * Angular hook to prevent individual routes.
   * @see https://angular.io/guide/router#canactivate-requiring-authentication
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    /** Only allow logged out users */
    if (!this.authService.checkIsLoggedIn()) {
      return true; // Tell Angular this route can be rendered ✅
    } else {
      /** Send users to dashboard if already logged in */
      void this.router.navigate(['/']);

      return false; // Tell Angular this route is out of bounds ❌
    }
  }
}

import { UserGetResponse } from '@views/misc/auth/auth.types';
import { convertEnumValueToTextFromKey } from '@views/misc/enums/enums.helpers';
import { FetchEnumsResponse } from '@views/misc/enums/enums.types';
import { IUserVerifyV2, IdVerificationStatusEnum } from '@views/misc/user/user.types';

import { EnumChipType } from '@components/chip/chip.component';

import {
  SquirrelMoneyContractsEnumerationsEntityTypes,
  SquirrelMoneyCoreEnumerationsAutoSellOptions,
} from '@core/schemas/api1-schema';

/**
 * Creates an image icon URL and alt text based on the provided entity type ID.
 *
 * @param entityTypeId - The ID of the entity type.
 * @param enums - The enumeration response containing entity type information.
 * @returns An object containing the image icon URL and alt text.
 * @property imageIconUrl - The URL of the image icon.
 * @property imageIconAlt - The alt text for the image icon.
 */
export const createImageIcon = (
  entityTypeId: number,
  enums: FetchEnumsResponse,
): {
  imageIconUrl: string;
  imageIconAlt: string;
} => {
  let entityName = convertEnumValueToTextFromKey(enums, 'entityTypeId', entityTypeId);

  entityName = entityName.replace(/[ ]/g, '');

  return {
    imageIconUrl: entityName && `/assets/images/investment-icons/${entityName}.png`,
    imageIconAlt: entityName && `${entityName} inventment icon`,
  };
};

export const CREATE_ENTITY_FLOW_ROUTE = `create-entity/profile`;
export const CREATE_ENTITY_FLOW_ROUTE_PREFIX = `/${CREATE_ENTITY_FLOW_ROUTE}`;

/**
 * Generates a route string based on the user's verification and profile information.
 *
 * @param {Object} params - The parameters object.
 * @param {IUserVerifyV2} params.userVerify - The user verification object.
 * @param {UserGetResponse} params.user - The user profile object.
 * @returns {string} The route string. If the user verification or profile is undefined, returns undefined.
 * If the user's address is empty, date of birth is null, or foreign tax resident status is null, returns the route for incomplete entity creation.
 * Otherwise, returns the route for creating an entity from the profile.
 */
export const createEntityFromHomeRoute = ({
  userVerify,
  user,
}: {
  userVerify: IUserVerifyV2;
  user: UserGetResponse;
}): string => {
  if (userVerify === undefined || user === undefined) {
    return undefined;
  }
  if (user?.address === '' || user?.dateOfBirthNzt === null || user?.foreignTaxResident === null) {
    return CREATE_ENTITY_FLOW_ROUTE_PREFIX + '/incomplete';
  }

  // otherwise go to create page
  return createEntityFromProfileRoute({ userVerify });
};

/**
 * Generates the appropriate route for creating an entity based on the user's ID verification status.
 *
 * @param {Object} params - The parameters object.
 * @param {IUserVerifyV2} params.userVerify - The user verification object.
 * @returns {string} The route to navigate to for creating an entity.
 *
 * If the user's ID verification status is either 'NotStarted' or 'Failed', the function returns the route to the ID validation page.
 * Otherwise, it returns the route to the entity creation page.
 */
export const createEntityFromProfileRoute = ({ userVerify }: { userVerify: IUserVerifyV2 }): string => {
  if (
    userVerify?.idVerificationStatus === IdVerificationStatusEnum.NotStarted ||
    userVerify?.idVerificationStatus === IdVerificationStatusEnum.Failed
  ) {
    return CREATE_ENTITY_FLOW_ROUTE_PREFIX + '/validate-id';
  }

  // otherwise go to create page
  return '/settings/entities/create';
};

/**
 * Returns a label describing the rollover status based on the provided auto-sell rollover investment option.
 *
 * @param autoSellRolloverInvestmentOnActivation - The auto-sell rollover investment option.
 * @returns A string label indicating the rollover status. Possible values are:
 * - 'Will keep' if the option is `SquirrelMoneyCoreEnumerationsAutoSellOptions.Keep`
 * - 'Will sell' if the option is `SquirrelMoneyCoreEnumerationsAutoSellOptions.Sell` or `SquirrelMoneyCoreEnumerationsAutoSellOptions.Undecided`
 * - `undefined` if the option does not match any known values
 */
export const returnRolloverStatusAsLabel = (
  autoSellRolloverInvestmentOnActivation: SquirrelMoneyCoreEnumerationsAutoSellOptions,
): string => {
  if (autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Keep) {
    return 'Will keep';
  } else if (
    autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Sell ||
    autoSellRolloverInvestmentOnActivation === SquirrelMoneyCoreEnumerationsAutoSellOptions.Undecided
  ) {
    // if ActivationId === 1(undecided) then set to 3(sell) as this is the default behaviour
    return 'Will sell';
  }
  return undefined;
};

/**
 * Determines the chip type for an investment account based on verification status and entity type.
 *
 * @param isVerified - A boolean indicating whether the account is verified.
 * @param entityType - The type of entity associated with the account, as defined by `SquirrelMoneyContractsEnumerationsEntityTypes`.
 * @returns The chip type as an `EnumChipType`:
 * - `EnumChipType.Active` if the account is verified.
 * - `EnumChipType.Pending` if the account is not verified and the entity is a person.
 * - `EnumChipType.Warning` if the account is not verified and the entity is not a person.
 */
export const getInvestmentAcountChipType = (
  isVerified: boolean,
  entityType: SquirrelMoneyContractsEnumerationsEntityTypes,
): EnumChipType => {
  if (isVerified === true) {
    return EnumChipType.Active;
  } else if (isVerified === false) {
    return entityType === SquirrelMoneyContractsEnumerationsEntityTypes.Person
      ? EnumChipType.Pending
      : // If the entity is not a person, then they will need to complete the entity checklist and we will show a warning chip
        EnumChipType.Warning;
  } else {
    return undefined;
  }
};

/**
 * Returns the status label for an investment account based on its verification status and entity type.
 *
 * @param isVerified - A boolean indicating whether the account is verified.
 * @param entityType - The type of entity associated with the account, which is an enumeration of `SquirrelMoneyContractsEnumerationsEntityTypes`.
 * @returns A string representing the status label of the investment account:
 * - 'Active' if the account is verified.
 * - 'Awaiting first deposit' if the account is not verified and the entity type is `Person`.
 * - 'Awaiting entity verification' if the account is not verified and the entity type is not `Person`.
 */
export const getInvestmentAccountStatusLabel = (
  isVerified: boolean,
  entityType: SquirrelMoneyContractsEnumerationsEntityTypes,
): string => {
  if (isVerified === true) {
    return 'Active';
  } else if (isVerified === false) {
    return entityType === SquirrelMoneyContractsEnumerationsEntityTypes.Person
      ? 'Awaiting first deposit'
      : 'Awaiting entity verification';
  } else {
    return undefined;
  }
};

/**
 * Determines if the entity checklist completion is required based on the verification status and entity type.
 *
 * @param isVerified - A boolean indicating whether the entity is verified.
 * @param entityType - The type of the entity, represented by the SquirrelMoneyContractsEnumerationsEntityTypes enumeration.
 * @returns A boolean indicating whether the entity checklist completion is required.
 */
export const entityChecklistCompletionRequired = (
  isVerified: boolean,
  entityType: SquirrelMoneyContractsEnumerationsEntityTypes,
): boolean => {
  return isVerified === false && entityType !== SquirrelMoneyContractsEnumerationsEntityTypes.Person;
};

import { Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';

import { ButtonVariant } from '@components/button/sq-button.directive';
import { SqButtonModule } from '@components/button/sq-button.module';

@Component({
  selector: 'app-snackbar',
  imports: [MatIcon, MatSnackBarModule, SqButtonModule],
  standalone: true,
  template: `
    @if (data?.icon) {
      <mat-icon fontSet="fa-solid" [fontIcon]="data?.icon" class="icon" />
    }

    <div class="message-wrapper">
      @if (data?.title) {
        <p class="title">{{ data?.title }}</p>
      }
      @if (data?.message) {
        <p class="message">{{ data?.message }}</p>
      }
    </div>

    @if (data?.buttonMessage) {
      <button
        sqButton
        [variant]="data?.variant ? data?.variant : 'primary'"
        size="small"
        (click)="clickAction()"
        class="button"
        [attr.data-testId]="data?.testId"
      >
        {{ data?.buttonMessage }}
      </button>
    }
  `,
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA)
    public data: {
      icon?: string;
      variant?: ButtonVariant;
      title?: string;
      message?: string;
      buttonMessage?: string;
      testId?: string;
    },
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
  ) {}

  clickAction(): void {
    this.snackBarRef.dismissWithAction();
  }
}

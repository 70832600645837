import { CdkDialogContainer, DialogModule } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
  selector: 'app-full-screen-dialog',
  standalone: true,
  imports: [DialogModule],
  template: `<ng-template cdkPortalOutlet />`,
  styleUrls: ['./full-screen-dialog.component.scss'],
})
export class FullScreenDialogContainer extends CdkDialogContainer {}

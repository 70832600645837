import { Action } from '@ngrx/store';

import { paths as paths1 } from '@core/schemas/api1-schema';
import { components as components2, paths as paths2 } from '@core/schemas/api2-schema';

import { IUserVerifyV2 } from '../user/user.types';

export enum NativeLoginTypes {
  Pin = 'pin',
  Biometric = 'Biometric',
}

export enum LocalStorage {
  longTermToken = 'TOKEN/LONG',
  shortTermToken = 'TOKEN/SHORT',
  userId = 'ID/USER',
  customerFormAuthToken = 'FORM_TOKEN/AUTH',
}

export enum PreferenceStorage {
  ReturningUser = 'Returning',
  IntroBiometric = 'INTRO_BIOMETRIC',
  IntroMFA = 'INTRO_MFA',
  IntroPin = 'INTRO_PIN',
  PendingNotification = 'PENDING_NOTIFICATION',
  InvestmentsCardType = 'INVESTMENTS_CARD_TYPE',
}

export enum ErrorKeys {
  invalidCredentials = 'InvalidCredentials',
  expiredVerifyToken = 'ExpiredVerifyToken',
  invalidToken = 'Invalid token',
}

export type DeviceCheckinPostRequest =
  paths1['/v2/devices/check-in']['post']['requestBody']['content']['application/json'];
export type DeviceCheckinPostResponse =
  paths1['/v2/devices/check-in']['post']['responses']['200']['content']['application/json'];

export type LoginRefreshPostRequest = paths2['/v2/login/refresh']['post']['requestBody']['content']['application/json'];
export type LoginRefreshPostResponse =
  paths2['/v2/login/refresh']['post']['responses']['200']['content']['application/json'];

export type LoginRefreshLongPostResponse =
  paths2['/v2/login/refresh/long']['post']['responses']['200']['content']['application/json'];

export type UserGetResponse = paths1['/v1/users/{userId}']['get']['responses']['200']['content']['application/json'];

export type UserDeactivatePostResponse =
  paths1['/v1/users/{userId}/deactivate']['post']['responses']['200']['content']['application/json'];

export type LoginPostRequest = paths2['/v2/login']['post']['requestBody']['content']['application/json'];
export type LoginDeleteRequest = paths2['/v2/login']['delete']['requestBody']['content']['application/json'];

export type ConfirmEmailPostRequest =
  paths1['/v1/account/confirmemail']['post']['requestBody']['content']['application/json'];

export type ForgotPasswordPostRequest =
  paths1['/v1/account/forgotpassword']['post']['requestBody']['content']['application/json'];

export type UpdatePasswordPostRequest =
  paths1['/v1/account/updatepassword']['post']['requestBody']['content']['application/json'];

export type ResetPasswordPostRequest =
  paths1['/v1/account/resetpassword']['post']['requestBody']['content']['application/json'];

export type HasPasswordPostRequest =
  paths1['/v1/account/haspassword']['post']['requestBody']['content']['application/json'];
export type HasPasswordPostResponse =
  paths1['/v1/account/haspassword']['post']['responses']['200']['content']['application/json'];

export type SetInitialPasswordPostRequest =
  paths1['/v1/account/setinitialpassword']['post']['requestBody']['content']['application/json'];
export type SetInitialPasswordPostResponse =
  paths1['/v1/account/setinitialpassword']['post']['responses']['200']['content']['application/json'];

export type RegisterPostRequest = paths2['/v2/register']['post']['requestBody']['content']['application/json'];
export interface AuthCreateUserSessionResponse extends LoginRefreshPostResponse {
  longTermToken: string;
  userId: number;
}

export type RegisterPostResponse = AuthCreateUserSessionResponse;

export type ForeignTaxResidencyForm = components2['schemas']['Squirrel.Money.Core.Contracts.ForeignTaxResidencyForm'];

export type registerRevokeDeviceRequest =
  paths1['/v3/devices/revoke']['post']['requestBody']['content']['application/json'];

export type registerDeviceRequest =
  paths1['/v3/devices/register']['post']['requestBody']['content']['application/json'];

export interface AuthState {
  isLoading: boolean;
  isError: boolean;
  user: UserGetResponse;
  userLoading: boolean;
  userLoaded: boolean;
  userVerify: IUserVerifyV2;
  userVerifyLoading: boolean;
  userVerifyLoaded: boolean;
}

export interface CreateGenericRequest {
  actions$: any; // Observable<any>;
  makeRequest: (payload: any) => any; // Observable<any>;
  successMessage?: string;
  errorMessage?: string;
  startAction: Action;
  successAction: Action;
  failAction: Action;
  useMergeMap?: boolean;
  passThroughPayloadOnSuccess?: boolean;
}

// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// Template. * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
// * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
export type RegisterInvestorFormAddress = Partial<{
  unitNumber: string;
  streetNumber: string;
  street: string;
  suburb: string;
  city: string;
  postCode: string;
  country: string;
  /** The complete formatted address string. Eg: "15/148 Howe St, Freemans Bay, Auckland 1011" */
  formatted: string;
  /** Unique meshblock code returned from the Addressable API */
  meshblock: number;
}>;

export interface RegisterInvestorFormTemplate {
  email?: string;
  password?: string;
  confirmPassword?: string;
  nameGroup?: {
    firstName?: string;
    middleNames?: string;
    lastName?: string;
  };
  dateOfBirthNzt?: string;
  phoneNumber?: string;
  hearAboutUsGroup?: {
    hearAboutUs?: string;
    hearAboutUsOther?: string;
  };
  usCitizen?: boolean;
  mainCountryOfTaxResidency?: string;
  socialSecurityNumber?: string;
  termsAndConditions?: boolean;
  acceptInvestorTerms?: boolean;
  taxResidencies?: {
    nzResidentOrCitizen?: boolean;
    foreignTaxResident?: boolean | null;
    foreignTaxResidencies?: ForeignTaxResidencyForm[] | null;
  };
  promoCode?: string;
  marketingGroup?: {
    optInSmsMarketing?: boolean;
    optInEmailMarketing?: boolean;
  };
  address?: RegisterInvestorFormAddress;
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SqButtonWrapperComponent } from './sq-button-wrapper.component';
import { SqButtonDirective, SqButtonOutlineDirective } from './sq-button.directive';

@NgModule({
  declarations: [],
  imports: [CommonModule, SqButtonDirective, SqButtonOutlineDirective, SqButtonWrapperComponent],
  exports: [SqButtonDirective, SqButtonOutlineDirective, SqButtonWrapperComponent],
})
export class SqButtonModule {}

export const formatBankAccountUtility = (value?: string): string => {
  let formatted: string;

  try {
    const bankNumber = value.substring(0, 2);
    const branchNumber = value.substring(2, 6);
    const accountNumber = value.substring(6, 13);
    const suffixNumber = value.substring(13);

    formatted = `${bankNumber}-${branchNumber}-${accountNumber}-${suffixNumber}`;
  } catch (error) {
    formatted = '';
  }

  return formatted;
};

export const BANK_ACCOUNT_MASK = '00-0000-0000000-00';

import { RegisterInvestorFormAddress } from '@views/misc/auth/auth.types';

// old legacy squirrel address format
export interface SquirrelAddress {
  address: string;
  addressUnit?: string;
  addressNo: string;
  addressStreet: string;
  addressCity: string;
  addressSuburb?: string;
  addressPostCode: string;
  addressCountry: string;
  addressMeshblock: number;
}

export const convertAddressIntoRequestData = (address: RegisterInvestorFormAddress): SquirrelAddress => {
  return {
    address: address?.formatted,
    addressUnit: address?.unitNumber,
    addressNo: address?.streetNumber,
    addressStreet: address?.street,
    addressSuburb: address?.suburb,
    addressCity: address?.city,
    addressPostCode: address?.postCode,
    addressCountry: address?.country,
    addressMeshblock: address?.meshblock,
  };
};

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-pin-pad-input',
  standalone: true,

  template: `
    <div class="inputs">
      @for (pinChar of [].constructor(pinMaxLength); track pinChar; let i = $index) {
        <div class="input" [class.complete]="i + 1 <= inputCount"></div>
      }
    </div>
  `,
  styleUrls: ['./pin-pad-input.component.scss'],
})
export class PinPadInputComponent {
  @Input() pinMaxLength: number;
  @Input() inputCount = 0;
}

import { RegisterInvestorFormTemplate } from '@views/misc/auth/auth.types';

import { convertAddressIntoRequestData } from '@core/utilities/address';

export const convertRegisterTemplateIntoRequestData = (formData: RegisterInvestorFormTemplate) => {
  const { address, nameGroup, hearAboutUsGroup, taxResidencies, marketingGroup, ...remainingItems } = formData;

  return {
    ...remainingItems,
    ...convertAddressIntoRequestData(address),
    firstName: nameGroup?.firstName,
    middleNames: nameGroup?.middleNames,
    lastName: nameGroup?.lastName,
    hearAboutUs: hearAboutUsGroup?.hearAboutUs,
    hearAboutUsOther: hearAboutUsGroup?.hearAboutUsOther,
    nzResidentOrCitizen: taxResidencies?.nzResidentOrCitizen,
    foreignTaxResident: taxResidencies?.foreignTaxResident,
    foreignTaxResidencies: taxResidencies?.foreignTaxResidencies,
    optInSmsMarketing: marketingGroup?.optInSmsMarketing,
    optInEmailMarketing: marketingGroup?.optInEmailMarketing,
  };
};

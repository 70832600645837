import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

import { computed, effect, inject } from '@angular/core';

import { patchState, signalStore, withComputed, withHooks, withMethods, withState } from '@ngrx/signals';

import { StorageService } from '../misc/auth/storage.service';

dayjs.extend(utc);

export type LoginTokenParams = {
  i: string;
  n: string;
  s: string;
  sv: string;
  t: string;
};

export type FormTokenParams = {
  sv: string;
  i: string;
  u: string;
  e: string;
  t: string;
  s: string;
};

type CustomerFormsState = {
  loginTokenParams: LoginTokenParams;
  formTokenParams: FormTokenParams;
  participantEmails: string[];
  authTokenSentToEmail: string;
};

const initialState: CustomerFormsState = {
  loginTokenParams: null,
  formTokenParams: null,
  authTokenSentToEmail: null,
  participantEmails: [],
};

export const CustomerFormsStore = signalStore(
  withState(initialState),

  withComputed(({ loginTokenParams, formTokenParams }) => ({
    loanApplicationId: computed(() => loginTokenParams()?.i || formTokenParams()?.i),
    loginAuthHeader: computed(() => {
      if (!loginTokenParams()?.sv) return null;

      return `Token sv=${loginTokenParams().sv}&i=${loginTokenParams().i}&n=${loginTokenParams().n}&t=${loginTokenParams().t}&s=${loginTokenParams().s}`;
    }),
    formAuthHeader: computed(() => {
      if (!formTokenParams()?.sv) return null;

      return `Token sv=${formTokenParams().sv}&i=${formTokenParams().i}&t=${formTokenParams().t}&s=${formTokenParams().s}&u=${formTokenParams().u}&e=${formTokenParams().e}`;
    }),
  })),

  withMethods((store, storageService = inject(StorageService)) => ({
    updateLoginTokenParams(loginTokenParams: LoginTokenParams): void {
      patchState(store, { loginTokenParams });
    },
    updateFormTokenParams(formTokenParams: FormTokenParams): void {
      patchState(store, { formTokenParams });
    },
    clearTokens(): void {
      patchState(store, { loginTokenParams: null, formTokenParams: null });
      storageService.clearCustomerFormAuthToken();
    },
    setParticipantEmails(participantEmails: string[]) {
      patchState(store, { participantEmails });
    },
    setSentAuthTokenToEmail(authTokenSentToEmail: string) {
      patchState(store, { authTokenSentToEmail });
    },
  })),
  withMethods(store => ({
    updateFormTokenParamsFromString(formTokenString: string): void {
      const formTokenParams = formTokenStringToFormTokenParams(formTokenString);
      store.updateFormTokenParams(formTokenParams);
    },
  })),

  withHooks({
    onInit(store, storageService = inject(StorageService)) {
      // Attempt to load formTokenParams from local storage
      const formTokenParams = JSON.parse(storageService.getCustomerFormAuthToken()) as FormTokenParams | null;
      if (formTokenParams) {
        // If token is expired clear it. Otherwise load it into store.
        isTokenExpired(formTokenParams.e) ? store.clearTokens() : store.updateFormTokenParams(formTokenParams);
      }

      effect(() => {
        // Save formTokenParams changes to local storage
        if (store.formTokenParams()) {
          storageService.setCustomerFormAuthToken(JSON.stringify(store.formTokenParams()));
        }
      });
    },
  }),
);

function formTokenStringToFormTokenParams(params: string): FormTokenParams {
  const formTokenParams = Object.fromEntries([...new URLSearchParams(params).entries()]);

  return {
    sv: formTokenParams.sv,
    i: formTokenParams.i,
    u: formTokenParams.u,
    e: formTokenParams.e,
    t: formTokenParams.t,
    s: formTokenParams.s,
  };
}

function isTokenExpired(expiresUtcString: string): boolean {
  const now = dayjs();
  const expiresAt = dayjs.utc(expiresUtcString);

  return now.isAfter(expiresAt);
}

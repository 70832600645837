import rg4js from 'raygun4js';

import { environment } from '@environments/environment';

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationError, Router } from '@angular/router';

import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { FirebasePerformance } from '@capacitor-firebase/performance';
import { Capacitor } from '@capacitor/core';

import { filter, map } from 'rxjs';

declare let gtag;

/**
 * Handles Google andlytics and firebase interactions
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  public setUser(userId: string): void {
    if (environment.firebase) {
      // only in firebase if a key is set, firebase is disabled in dev mode
      FirebaseAnalytics.setUserId({
        userId,
      });
      if (Capacitor.isNativePlatform()) {
        // crashlytics does not work on web
        FirebaseCrashlytics.setUserId({
          userId,
        });
      }
    }
    if (environment.rayGunApiKey) {
      rg4js('setUser', {
        identifier: userId, // A unique ID, email address, or another ID such as a UUID
        isAnonymous: false,
      });
    }
  }

  public event(eventName: string, params: Record<string, string | number>): void {
    if (environment.googleAnalyticsKey) {
      gtag('event', eventName, params);
    }
    if (environment.firebase) {
      // only in firebase if a key is set, firebase is disabled in dev mode
      FirebaseAnalytics.logEvent({
        name: eventName,
        params,
      });
    }
  }

  public init(): void {
    this.listenForRouteChanges();
    if (environment.googleAnalyticsKey) {
      try {
        const script1 = document.createElement('script');
        script1.async = true;
        script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey.measurementId;
        document.head.appendChild(script1);

        // Init must be external to avoid csp blocking
        const script2 = document.createElement('script');
        script2.async = true;
        script2.id = 'google-analytics';
        script2.setAttribute('ga4', environment.googleAnalyticsKey.measurementId);
        script2.src = '/assets/js/ga_init.js';
        document.head.appendChild(script2);
      } catch (ex) {
        // console.error('Error appending google analytics');
        // console.error(ex);
      }
    }
    if (environment.firebase) {
      // only in firebase if a key is set, firebase is disabled in dev mode
      FirebaseAnalytics.setEnabled({
        enabled: true,
      });
      FirebasePerformance.setEnabled({ enabled: true });
      if (Capacitor.isNativePlatform()) {
        // crashlytics does not work on web
        FirebaseCrashlytics.setEnabled({
          enabled: true,
        });
      }
    }
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // track navigation
        if (environment.rayGunApiKey) {
          rg4js('trackEvent', {
            type: 'pageView',
            path: event.url,
          });
        }
        if (environment.googleAnalyticsKey) {
          // only in analytics if a key is set, analytics is disabled in dev mode
          gtag('config', environment.googleAnalyticsKey.measurementId, {
            page_path: event.urlAfterRedirects,
          });
        }
      }
      if (event instanceof NavigationError) {
        // Track navigation error
        if (environment.rayGunApiKey) {
          rg4js('send', {
            error: event.error,
          });
        }
      }
    });

    if (environment.firebase) {
      // only in firebase if a key is set, firebase is disabled in dev mode
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          map(() => {
            let child = this.activatedRoute.firstChild;
            // Get the title from the lowest view
            while (child.firstChild) {
              child = child.firstChild;
            }
            if (child.snapshot.data.title) {
              return `${child.snapshot.data.title as string}`;
            }
            return '';
          }),
        )
        .subscribe((screenName: string) => {
          FirebaseAnalytics.setCurrentScreen({
            screenName,
          });
        });
    }
  }
}

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import rg4js from 'raygun4js';

import { environment } from '@environments/environment';

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { HttpErrorState } from '../networkLayer/interceptor.types';
import { LoggerRequestType } from './logger.types';

@Injectable()
export class LoggerService {
  private appInsights;
  constructor(
    private router: Router,
    private ngZone: NgZone,
  ) {
    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsightsInstrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router },
        },
      },
    });
    this.ngZone.runOutsideAngular(() => {
      this.appInsights.loadAppInsights();
    });
  }

  logPageView(name?: string, url?: string): void {
    // option to call manually
    this.appInsights.trackPageView({
      name,
      uri: url,
    });
  }

  logEvent(p: { message: string; errorResponse?: HttpErrorResponse; errorState?: HttpErrorState[] }): void {
    this.appInsights.trackEvent(
      { name: p.message },
      {
        type: LoggerRequestType.apiError,
        errorResponse: p.errorResponse,
        errorState: p.errorState,
      },
    );
  }

  logMetric(name: string, average: number, properties?: { [key: string]: string | number }): void {
    this.appInsights.trackMetric({ name, average }, properties);
  }

  logException(exception: Error, severityLevel?: number): void {
    if (environment.rayGunApiKey) {
      rg4js('send', {
        error: exception,
      });
    }
    this.appInsights.trackException({ exception, severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: string | number }): void {
    this.appInsights.trackTrace({ message }, properties);
  }
}

import { DEFAULT_DIALOG_CONFIG, DIALOG_SCROLL_STRATEGY, Dialog, DialogConfig, DialogRef } from '@angular/cdk/dialog';
import { ComponentType, Overlay, OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable, Injector, Optional, SkipSelf } from '@angular/core';

import { FullScreenDialogContainer } from './full-screen-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class FullScreenDialog extends Dialog {
  constructor(
    private overlay: Overlay,
    _injector: Injector,
    public injector: Injector,
    @Optional() @Inject(DEFAULT_DIALOG_CONFIG) _dialogConfig: DialogConfig,
    public _overlayContainerRef: OverlayContainer,
    @Inject(DIALOG_SCROLL_STRATEGY) scrollStrategy: any,
    @Optional() @SkipSelf() _parentDialog: Dialog,
    @Optional() public dialogRef: DialogRef<any>,
  ) {
    super(overlay, _injector, _dialogConfig, _parentDialog, _overlayContainerRef, scrollStrategy);
  }

  /**
   * Open a custom component in an overlay
   */
  openDialog<T>(component: ComponentType<T>, config?: DialogConfig): DialogRef<unknown, any> {
    const defaultSettings = {
      height: '100%',
      width: '100%',
      maxWidth: 1200,
      closeOnNavigation: true,
      closeOnDestroy: true,
      autoFocus: false,
      positionStrategy: this.overlay.position().global().bottom('0px').right('0px'),
      container: FullScreenDialogContainer,
    };

    config = { ...defaultSettings, ...config };
    return super.open(component, config);
  }
}

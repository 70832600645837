export enum BAD_IDENTITY_REASON_ERRORS {
  NoValidVerifications = 'No valid verifications',
  IDExpired = 'Identification has expired',
}

export enum USER_MESSAGES {
  NandPIncomplete = 'NandPIncomplete',
  IdentificationFailed = 'IdentificationFailed',
  UnvalidatedEmail = 'UnvalidatedEmail',
  TandCsIsNull = 'TandCsIsNull',
  IdentificationValidating = 'IdentificationValidating',
  ForeignTaxResidenciesIncomplete = 'ForeignTaxResidenciesIncomplete',
}

export interface IBadIdentityReason {
  identity: string;
  userInput: string;
  dataRead: string;
  error: string;
  onlyError: boolean;
}

export interface IUserVerifyV1 {
  idVerificationSuccess: boolean;
  idVerificationInProgress: boolean;
  idVerificationFailed: boolean;
  idVerificationNotStarted: boolean;
  badIdentityData: boolean;
  badIdentityReasons: IBadIdentityReason[];
  badHeaderCheckReasons: string;
  headerCheckInProgress: boolean;
  headerCheckValid: boolean;
  driversLicenceMatched: boolean;
  driversLicenceVersion: string;
  manualHeaderCheck: boolean;
  manualWatchlistCheck: boolean;
  investorTermsAcceptedNzt: string;
  disclosureStatementAcceptedNzt: string;
  hasAllValidVerifications: boolean;
  active: boolean;
  isInvestor: boolean;
  isBorrower: boolean;
  isVerified: boolean;
  verificationInProgress: boolean;
  emailConfirmed: boolean;
  id: number;
  fullName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export enum IdVerificationStatusEnum {
  None = 0,
  NotStarted = 1,
  InProgress = 2,
  Success = 3,
  Failed = 4,
  Expired = 5,
}

export interface IUserVerifyV2 {
  id: number;
  fullName: string;
  email: string;
  firstName: string;
  lastName: string;
  active: boolean;
  isInvestor: boolean;
  isBorrower: boolean;
  isVerified: boolean;
  /* isVerified = contact.EmailConfirmed
   * && string.IsNullOrEmpty(contact.FirstName) == false
   * && string.IsNullOrEmpty(contact.LastName) == false
   * && string.IsNullOrEmpty(contact.Address) == false
   * && IdVerificationSuccess(user) <- authentix.Success
   * && contact.DateOfBirthNzt.HasValue
   * && contact.DateOfBirthNzt.Value.AddYears(18) <= DateTime.Today;
   */
  verificationInProgress: boolean;
  emailConfirmed: boolean;
  idVerificationStatus: IdVerificationStatusEnum;
  headerCheckInProgress: boolean; // header check is missing but not because of an invalid ID or user details
  headerCheckValid: boolean; // header check is valid or has been overridden as valid by admin
  driversLicenceVersion: string; // the user's drivers license version
  manualHeaderCheck: boolean; // if a manual header check has been demanded by admin
  manualWatchlistCheck: boolean; // if a manual watchlist check has been demanded by admin
  investorTermsAcceptedNzt: string;
  disclosureStatementAcceptedNzt: string;
  isNameVerified: boolean;
  isDateOfBirthVerified: boolean;
  isAddressVerified: boolean;
  hasAllValidVerifications: boolean; // => this.headerCheckValid && this.badIdentityData == false && this.idVerificationSuccess
}

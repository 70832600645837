import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export type ButtonVariant = 'primary' | 'secondary' | 'warn' | 'disabled';
export type ButtonSize = 'small' | 'regular';

/**
 * Directive to apply custom button styles to an element.
 *
 * This directive adds a base class `sq-button` to the host element and manages
 * additional classes based on the `variant` and `size` inputs. It also handles
 * changes to these inputs dynamically.
 *
 * @example
 * ```html
 * <button sqButton variant="primary" size="regular">Primary Button</button>
 * ```
 *
 * @example
 * ```html
 * <button sqButton variant="warn" size="small" [disabled]="true">Disabled Warn Button</button>
 * ```
 *
 * @export
 * @class SqButtonDirective
 * @implements {OnInit}
 * @implements {OnChanges}
 */

@Directive({
  selector: '[sqButton]',
  standalone: true,
})
export class SqButtonDirective implements OnInit, OnChanges {
  @Input() variant: ButtonVariant = 'primary';
  @Input() size: ButtonSize = 'regular';
  constructor(private el: ElementRef) {
    this.el.nativeElement.classList.add('sq-button');
  }

  ngOnInit() {
    this.setColorClass();
    this.setSizeClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.variant) {
      this.setColorClass();
    }
    if (changes.size) {
      this.setSizeClass();
    }
  }

  private setColorClass() {
    // Remove all variant classes
    const variantClasses = ['sq-button-primary', 'sq-button-secondary', 'sq-button-warn', 'sq-button-disabled'];
    variantClasses.forEach(variantClass => this.el.nativeElement.classList.remove(variantClass));
    // Add the correct variant class
    const variantClassMap: { [key in ButtonVariant]: string } = {
      primary: 'sq-button-primary',
      secondary: 'sq-button-secondary',
      warn: 'sq-button-warn',
      disabled: 'sq-button-disabled',
    };

    this.el.nativeElement.classList.add(variantClassMap[this.variant]);
  }

  private setSizeClass() {
    const sizeClasses = ['sq-button-small', 'sq-button-regular'];
    sizeClasses.forEach(sizeClass => this.el.nativeElement.classList.remove(sizeClass));
    this.el.nativeElement.classList.add(`sq-button-${this.size}`);
  }
}

/**
 * Directive that extends the functionality of `SqButtonDirective` to add an outline style to buttons.
 *
 * This directive adds the CSS class `sq-button-outline` to the host element, which applies the outline style.
 *
 * @extends SqButtonDirective
 *
 * @example
 * ```html
 * <button sqButtonOutline>Outlined Button</button>
 * ```
 */
@Directive({
  selector: '[sqButtonOutline]',
  standalone: true,
})
export class SqButtonOutlineDirective extends SqButtonDirective {
  constructor(el: ElementRef) {
    super(el);
    el.nativeElement.classList.add('sq-button-outline');
  }
}

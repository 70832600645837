import { createAction, props } from '@ngrx/store';

export const navigationUpdateBackRoute = createAction(
  'NAVIGATION/UPDATE_BACK_ROUTE',
  props<{ backRoute: string | any[] }>(),
);
export const navigationUpdateCurrentViewTitle = createAction(
  'NAVIGATION/UPDATE_CURRENT_VIEW_TITLE',
  props<{ currentViewTitle: string }>(),
);
export const navigationResetBackRoute = createAction('NAVIGATION/RESET_BACK_ROUTE');
export const navigationReset = createAction('NAVIGATION/RESET');

import { environment } from '@environments/environment';

import { DialogRef } from '@angular/cdk/dialog';
import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';

import { FirebaseMessaging } from '@capacitor-firebase/messaging';

import { Store } from '@ngrx/store';

import { Observable, Subject } from 'rxjs';

import { SqButtonModule } from '@components/button/sq-button.module';
import { FullScreenDialogContentComponent } from '@components/full-screen-dialog/full-screen-dialog-content.component';
import { PageLoaderComponent } from '@components/page-loader/page-loader.component';

import { NotificationComponent, NotificationComponentOutput } from './notification.component';
import {
  notificationsFetchCurrentLimit,
  notificationsFetchMore,
  notificationsMarkAllAsReadStart,
  notificationsResetList,
  notificationsUpdateItemStart,
} from './notifications.actions';
import { returnNotificationRoute } from './notifications.helper';
import {
  selectNotificationsIsLoaded,
  selectNotificationsIsLoading,
  selectNotificationsMoreResultsAvailable,
  selectNotificationsTemplate,
} from './notifications.selectors';
import { NotificationItemTemplate } from './notifications.types';

@Component({
  selector: 'app-notifications',
  standalone: true,
  imports: [
    FullScreenDialogContentComponent,
    MatProgressSpinner,
    PageLoaderComponent,
    NotificationComponent,
    AsyncPipe,
    SqButtonModule,
  ],
  template: `
    <app-full-screen-dialog-content title="Notifications">
      @if (isLoaded$ | async) {
        <!-- <app-section-cell label="Token" [data]="token" [enableCopy]="true"> </app-section-cell>
        <button mat-fab color="primary" (click)="getToken()">Get</button> -->
        <sq-button-wrapper>
          <button sqButton variant="primary" (click)="markAllAsRead()" data-testid="mark-all-read">
            Mark all as read
          </button>
        </sq-button-wrapper>
        @for (notification of notifications$ | async; track trackNotificationBy(i, notification); let i = $index) {
          <app-notification
            [notification]="notification"
            [nextNotificationNotRead]="(notifications$ | async)[i + 1]?.isRead === false"
            (notificationEvent)="notificationEvent($event)"
          />
        }
        @if (moreResultsAvailable$ | async) {
          <sq-button-wrapper>
            <button sqButtonOutline variant="primary" type="button" (click)="loadMore()">
              @switch (isLoading$ | async) {
                @default {
                  Load more
                }
                @case (true) {
                  <mat-spinner diameter="32" color="primary" />
                }
              }
            </button>
          </sq-button-wrapper>
        }
      }
      @if ((isLoaded$ | async) === false) {
        <app-page-loader />
      }
    </app-full-screen-dialog-content>
  `,
})
export class NotificationsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  notifications$: Observable<NotificationItemTemplate[]>;
  moreResultsAvailable$: Observable<boolean>;
  isLoaded$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  public token = '';

  constructor(
    private store: Store,
    private router: Router,
    public dialogRef: DialogRef,
  ) {}

  ngOnInit(): void {
    this.notifications$ = this.store.select(selectNotificationsTemplate);
    this.moreResultsAvailable$ = this.store.select(selectNotificationsMoreResultsAvailable);
    this.isLoading$ = this.store.select(selectNotificationsIsLoading);
    this.isLoaded$ = this.store.select(selectNotificationsIsLoaded);
    this.store.dispatch(notificationsFetchCurrentLimit());
    if (environment.firebase !== undefined) {
      // Remove any notifications if they directly go to the notification side view, this is so the notifications badge is clear
      FirebaseMessaging.removeAllDeliveredNotifications();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    /**
     * Empty out the list of data so that:
     * - - - - - - - - - - - - - - - - - -
     * 1. We are not displaying stale data when the user returns to the section.
     */
    this.store.dispatch(notificationsResetList());
  }

  // async getToken(): Promise<void> {
  //   if (environment.firebase !== undefined) {
  //     const options: GetTokenOptions = {
  //       vapidKey: environment.firebase.vapidKey,
  //     };
  //     if (Capacitor.getPlatform() === 'web') {
  //       options.serviceWorkerRegistration = await navigator.serviceWorker.register('firebase-messaging-sw.js');
  //     }
  //     const { token } = await FirebaseMessaging.getToken(options);
  //     this.token = token;
  //   }
  // }

  loadMore(): void {
    this.store.dispatch(notificationsFetchMore());
  }

  markAllAsRead(): void {
    this.store.dispatch(notificationsMarkAllAsReadStart());
  }

  trackNotificationBy = (index, notification: NotificationItemTemplate): number => notification?.notificationId;

  notificationEvent(e: NotificationComponentOutput): void {
    if (e.action === 'view') {
      const { notificationTypeId, notificationId, entityId, investmentId, investmentOfferId, investmentOrderId } =
        e.notification;
      /**
       * First dispatch an action to update the item
       */
      this.store.dispatch(notificationsUpdateItemStart({ payload: { notificationId } }));

      /**
       * then redirect the user to the correct page based on the notificationType
       */
      const redirectRoute = returnNotificationRoute({
        notificationType: notificationTypeId,
        entityId,
        investmentId,
        investmentOfferId,
        investmentOrderId,
      });
      if (redirectRoute) {
        this.dialogRef.close();
        void this.router.navigate(redirectRoute);
      }
    }
  }
}

import { Injectable } from '@angular/core';

import { LocalStorage } from './auth.types';

/**
 * For server related runner sequences (app shell etc) we do NOT have access to
 * localStorage which is a critical construct that gets called to determine HOW
 * to render a page (is the user authenticated).
 *
 * We do not case about the user when rendering static scaffolds, so we check for
 * localStorage existence here and ONLY run their subsequent commands if we have
 * access to the API.
 */
const HAS_LOCALSTORAGE = (() => {
  let hasLocalStorage: boolean;

  try {
    if (window && window.localStorage) {
      hasLocalStorage = true;
    }
  } catch (error) {
    hasLocalStorage = false;
  }

  return hasLocalStorage;
})();

/**
 * The storage service handles the setting and getting of local and native storage
 */
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // Local Tokens / ID's.  * * * * * * * * * * * * * * * * * * * * * * * * * * *
  // * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *

  setLocalRef =
    (key: string) =>
    (value: string): void =>
      HAS_LOCALSTORAGE && localStorage.setItem(key, value);
  clearLocalRef = (key: string) => (): void => HAS_LOCALSTORAGE && localStorage.removeItem(key);
  getLocalRef = (key: string) => (): string => HAS_LOCALSTORAGE && localStorage.getItem(key);
  hasLocalRef = (key: string) => (): boolean => HAS_LOCALSTORAGE && Boolean(localStorage.getItem(key));

  // Long Token.
  // - - - - - -
  /** Set a new Long Token */
  setLongToken = (value: string): void => this.setLocalRef(LocalStorage.longTermToken)(value);
  /** Clear the current Long Token */
  clearLongToken = (): void => this.clearLocalRef(LocalStorage.longTermToken)();
  /** Get the current Long Token */
  getLongToken = (): string => this.getLocalRef(LocalStorage.longTermToken)();
  /** Check if a Long Token exists */
  hasLongToken = (): boolean => this.hasLocalRef(LocalStorage.longTermToken)();

  // Short Token.
  // - - - - - -
  /** Set a new Short Token */
  setShortToken = (value: string): void => this.setLocalRef(LocalStorage.shortTermToken)(value);
  /** Clear the current Short Token */
  clearShortToken = (): void => this.clearLocalRef(LocalStorage.shortTermToken)();
  /** Get the current Short Token */
  getShortToken = (): string => this.getLocalRef(LocalStorage.shortTermToken)();
  /** Check if a Short Token exists */
  hasShortToken = (): boolean => this.hasLocalRef(LocalStorage.shortTermToken)();

  // User ID.
  // - - - -
  /** Set a new User ID */
  setUserId = (value: string): void => this.setLocalRef(LocalStorage.userId)(value);
  /** Clear the current User ID */
  clearUserId = (): void => this.clearLocalRef(LocalStorage.userId)();
  /** Clear the current User ID */
  /** Get the current User ID */
  getUserId = (): string => this.getLocalRef(LocalStorage.userId)();
  /** Check if a User ID exists */
  hasUserId = (): boolean => this.hasLocalRef(LocalStorage.userId)();

  // Customer form auth token.
  // - - - -
  /** Set a new customer form auth token */
  setCustomerFormAuthToken = (value: string): void => this.setLocalRef(LocalStorage.customerFormAuthToken)(value);
  /** Clear the current customer form auth token */
  clearCustomerFormAuthToken = (): void => this.clearLocalRef(LocalStorage.customerFormAuthToken)();
  /** Clear the current customer form auth token */
  /** Get the current customer form auth token */
  getCustomerFormAuthToken = (): string => this.getLocalRef(LocalStorage.customerFormAuthToken)();
  /** Check if a customer form auth token exists */
  hasCustomerFormAuthToken = (): boolean => this.hasLocalRef(LocalStorage.customerFormAuthToken)();
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

import { SetPinComponent } from '@components/pin/set-pin.component';

import { PreferenceStorage } from '../auth/auth.types';
import { NewUserStepActionEvent, NewUserStepActionsComponent } from './new-user-step-actions.component';

@Component({
  selector: 'app-new-user-step-pin',
  standalone: true,
  imports: [NewUserStepActionsComponent, SetPinComponent],
  template: `
    <app-set-pin [step]="step" [steps]="steps" (pinCompleted)="pinCompleted()" />

    @switch (pinSet) {
      @default {
        <app-new-user-step-actions secondaryLabel="I'll do this later" (buttonClicked)="buttonClicked($event)" />
      }
      @case (true) {
        <app-new-user-step-actions
          [primaryLabel]="step === undefined || step === steps ? 'All done!' : 'Next'"
          (buttonClicked)="buttonClicked($event)"
        />
      }
    }
  `,
  styleUrls: ['./new-user-step-pin.component.scss'],
})
export class NewUserStepPinComponent {
  @Input() step?: number;
  @Input() steps?: number;
  @Output() next = new EventEmitter();
  pinSet = false;

  pinCompleted(): void {
    this.pinSet = true;
  }

  buttonClicked(e: NewUserStepActionEvent): void {
    this.next.emit();

    // We save the preference so we don't ask the question again on this device
    Preferences.set({ key: PreferenceStorage.IntroPin, value: 'true' });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatRipple } from '@angular/material/core';

import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Component({
  selector: 'app-pin-pad-button',
  standalone: true,
  imports: [MatRipple],
  template: `<button
    class="button"
    matRipple
    [matRippleDisabled]="disabled"
    [class.disabled]="disabled"
    index="-1"
    (click)="buttonClick()"
  >
    @if (icon) {
      <i class="icon fa-light {{ icon }}"></i>
    }
    @if (label) {
      <span class="label">{{ label }}</span>
    }
    @if (number) {
      <span class="number">{{ number }}</span>
    }
    @if (alpha) {
      <span class="alpha">{{ alpha }}</span>
    }
  </button>`,
  styleUrls: ['./pin-pad-button.component.scss'],
})
export class PinPadButtonComponent {
  @Input() icon?: string;
  @Input() label?: string;
  @Input() number?: string;
  @Input() alpha?: string;
  @Input() disabled = false;
  @Output() onButtonClick = new EventEmitter();

  buttonClick(): void {
    Haptics.impact({ style: ImpactStyle.Light });
    this.onButtonClick.emit();
  }
}

import { components, paths } from '@core/schemas/api1-schema';

export type NotificationDto = components['schemas']['Squirrel.Money.Core.Contracts.NotificationDto'];
export type NotificationsReadPutResponse =
  paths['/v1/notifications/{notificationId}/read']['put']['responses']['200']['content']['application/json'];
export type NotificationsGetQuery = paths['/v1/notifications']['get']['parameters']['query'];
export type NotificationsGetResponse =
  paths['/v1/notifications']['get']['responses']['200']['content']['application/json'];

export type NotificationsUnreadCountGetResponse =
  paths['/v1/notifications/unread-count']['get']['responses']['200']['content']['application/json'];

export interface NotificationsState {
  notificationsResponse: NotificationsGetResponse;
  loadCount: number;
  unreadCount: number;
  isLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
}

export interface NotificationItemTemplate extends NotificationDto {
  createDateNztDisplay: string;
}

export enum NotificationTypes {
  Repayment = 1,
  Deposit = 2,
  InvestmentStart = 3,
  Admin = 4,
  InvestmentSold = 5,
  InvestmentOffer = 6,
  InvestmentRollover = 7,
}

export enum NotificationActions {
  ViewBankAccountTransactions = 'ViewBankAccountTransactions',
  ViewNotifications = 'ViewNotifications',
  ViewInvestmentSummary = 'ViewInvestmentSummary',
  ViewInvestmentOffer = 'ViewInvestmentOffer',
  ViewInvestmentOrders = 'ViewInvestmentOrders',
}

export interface NotificationEventData {
  MarkAsRead: 'True' | 'False';
  Action: NotificationActions;
  EntityId: string;
  UserId: string;
  NotificationId: string;
  BankAccountId?: string;
  InvestmentId?: string;
  InvestmentOfferId?: string; // Only applied to NotificationActions.ViewInvestmentOffer
  InvestmentOrderId?: string; // Only applied to NotificationActions.ViewInvestmentOffer
  aps: { alert: { body: string; tittle: string } };
}

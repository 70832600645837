import { Component, Input } from '@angular/core';

/**
 * The `SqButtonWrapperComponent` is a wrapper component for buttons.
 *
 * @property {boolean} disableFullWidth - If true, buttons will always stay on one line and not take up the full width of the container.
 *                                        Defaults to `false`.
 */
@Component({
  selector: 'sq-button-wrapper',
  standalone: true,
  template: `<div class="sq-button-wrapper" [class.sq-button-disable-full-width]="disableFullWidth">
    <ng-content />
  </div>`,
  styleUrl: './sq-button-wrapper.component.scss',
})
export class SqButtonWrapperComponent {
  // If true buttons will always stay on one line and not take up the full width of the container
  @Input()
  disableFullWidth: boolean = false;
}

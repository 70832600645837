import dayjs from 'dayjs';
import 'dayjs/locale/en-nz';

import { createFeatureSelector, createSelector } from '@ngrx/store';

import { MEDIUM_DATE_DISPLAY } from '@core/utilities/format/date/format-date.utility';

import { NotificationItemTemplate, NotificationsState } from './notifications.types';

export const selectNotificationsState = createFeatureSelector<NotificationsState>('notifications');

export const selectNotificationsUnreadCount = createSelector(
  selectNotificationsState,
  notificationsState => notificationsState?.unreadCount,
);

export const selectNotificationsLoadCount = createSelector(
  selectNotificationsState,
  notificationsState => notificationsState?.loadCount,
);

export const selectNotificationsIsLoading = createSelector(
  selectNotificationsState,
  notificationsState => notificationsState?.isLoading,
);
export const selectNotificationsIsLoaded = createSelector(
  selectNotificationsState,
  notificationsState => notificationsState?.isLoaded,
);

export const selectNotificationResponse = createSelector(
  selectNotificationsState,
  notificationsState => notificationsState?.notificationsResponse,
);

export const selectNotifications = createSelector(
  selectNotificationResponse,
  notificationsResponse => notificationsResponse?.items,
);

export const selectNotificationsMetadata = createSelector(
  selectNotificationResponse,
  notificationsResponse => notificationsResponse?.metadata,
);

export const selectNotificationsTemplate = createSelector(
  selectNotifications,
  (notifications): NotificationItemTemplate[] =>
    notifications?.map(notification => ({
      ...notification,
      createDateNztDisplay: notification?.createDateNzt
        ? dayjs(notification?.createDateNzt).locale('en-nz').format(`h:mma, ${MEDIUM_DATE_DISPLAY}`)
        : undefined,
    })),
);

export const selectNotificationsMoreResultsAvailable = createSelector(selectNotificationsMetadata, metadata =>
  metadata ? metadata?.totalCount > metadata?.pageSize : false,
);

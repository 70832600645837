import { HttpEvent, HttpRequest, HttpResponse } from '@angular/common/http';

/**
 * ErrorCodes created in the interceptor to handle special cases
 */
export enum SpecialErrorCodes {
  // Add special errorcode 1001 for expired id,
  // this is a short term fix as we don't have a good solution for
  // finding the error we have to use a string match.
  // Should be replace with better solution from backend;
  IdExpired = '1001',

  // If the errorCode is InvalidValue this means the error is from
  // an invalid form value so we will give it a special errorCode
  InvalidValue = '1002',

  // AuthSignal - if an api returns a 499 error it means it multifactor
  // authentication via an AuthSignal token is required.
  MfaRequired = '499',
}

export const interceptorModifiyResponse = (
  res: HttpEvent<any>,
  contentNegotiation: boolean,
  request: HttpRequest<any>,
): HttpEvent<any> => {
  if (contentNegotiation && request.responseType !== 'blob') {
    // Skip this step if response type is blob as its wont have a payload
    if (res instanceof HttpResponse) {
      const modifiedResponse = res.clone({
        body: res.body ? res.body.payload : null,
      });
      return modifiedResponse;
    }
  } else {
    return res;
  }
};
